import { createRouter, createWebHashHistory } from 'vue-router'
import { ElMessage } from 'element-plus'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      title: 'Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    children: [
      {
        path: 'customer-requests',
        name: 'customer-requests',
        component: () => import('@/views/Admin/RequireApprovalList.vue'),
        meta: {
          title: 'Customer Request | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'web-orders',
        name: 'admin-web-orders',
        component: () => import('@/views/Admin/WebOrders.vue'),
        meta: {
          title: 'Web Orders | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'banner',
        name: 'admin-banner',
        component: () => import('@/views/Admin/BannerManagement.vue'),
        meta: {
          title: 'Manage Banners | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'products',
        name: 'admin-products',
        component: () => import('@/views/Admin/ProductsList.vue'),
        meta: {
          title: 'Manage Products | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'edit/:product',
        name: 'admin-edit-product',
        component: () => import('@/views/Admin/ProductEditor.vue'),
        meta: {
          title: 'Edit Products | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'subcategories',
        name: 'admin-subcategories',
        component: () => import('@/views/Admin/SubcategoryList.vue'),
        meta: {
          title: 'Manage Subcategories | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () => import('@/views/Admin/UserList.vue'),
        meta: {
          title: 'Manage Users | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'web-customers',
        name: 'admin-web-customers',
        component: () => import('@/views/Admin/WebCustomer.vue'),
        meta: {
          title: 'Web Customers | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      },
      {
        path: 'sub-colours',
        name: 'admin-sub-colours',
        component: () => import('@/views/Admin/SubColour.vue'),
        meta: {
          title: 'Sub Colours | Woodworking Machinery, Materials and Components - NEY Ltd'
        }
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => import('@/views/Admin/AdminLogin.vue'),
    meta: {
      title: 'Admin Login | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About/AboutUs.vue'),
    meta: {
      title: 'About Us | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/About/ContactUs.vue'),
    meta: {
      title: 'Contact Us | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/ProwoodFinance.vue'),
    meta: {
      title: 'Finance | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order/OrderResult.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/place-order',
    name: 'place-order',
    component: () => import('@/views/Order/EasiglideOrderResult.vue'),
    meta: {
      requiresAuth: true,
      title: 'Order | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Member/OrderList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Orders | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: () => import('@/views/Member/AddressList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Addresses | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/paymentanddispatch',
    name: 'paymentanddispatch',
    component: () => import('@/views/Policy/PaymentDispatch.vue'),
    meta: {
      title: 'payment and Dispatch | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/views/Policy/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/Products/AllProducts.vue'),
    meta: {
      title: 'Products | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/products/:product',
    name: 'product-detail',
    component: () => import('@/views/Products/ProductDetails.vue'),
    meta: {
      title: 'Product Detail | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/Member/MemberProfile.vue'),
    meta: {
      requiresAuth: true,
      title: 'Profile | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Member/SignUp.vue'),
    meta: {
      title: 'Register | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/Member/VerifyEmail.vue'),
    meta: {
      title: 'Verify Email | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/return',
    name: 'return',
    component: () => import('@/views/Policy/ReturnToUs.vue'),
    meta: {
      title: 'Return | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/termsconditions',
    name: 'termsconditions',
    component: () => import('@/views/Policy/TermsConditions.vue'),
    meta: {
      title: 'Term and Conditions | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import('@/views/Member/WishList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Wishlists | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Order/ShoppingCart.vue'),
    meta: {
      requiresAuth: true,
      title: 'Carts | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/shippinginfo',
    name: 'shippinginfo',
    component: () => import('@/views/Order/ShippingInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/easiglide-door-order',
    name: 'easiglide-door-order',
    component: () => import('@/views/Order/EasiglideShippingInfo.vue'),
    meta: {
      requiresAuth: true,
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/door-carts',
    name: 'door-carts',
    component: () => import('@/views/Order/EasiglideShippingInfo1.vue'),
    meta: {
      requiresAuth: true,
      title: 'Shipping Info | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/Order/CheckOut.vue'),
    meta: {
      title: 'Payment | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/door-payment',
    name: 'door-payment',
    component: () => import('@/views/Order/EasiglideCheckOut.vue'),
    meta: {
      title: 'Payment | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Products/SearchPage.vue'),
    meta: {
      title: 'Search Results | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/3dservice',
    name: '3dservice',
    component: () => import('@/views/Products/3DService.vue'),
    meta: {
      title: '3D Service | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/easiglide',
    name: 'easiglide',
    component: () => import('@/views/Products/EasiglideDoors.vue'),
    meta: {
      title: 'Easiglide Door Entry System | Woodworking Machinery, Materials and Components - NEY Ltd'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/404Page.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
      .forEach(tag => document.head.appendChild(tag))
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*=\s*([^;]*).*$)|^.*$/, '$1')
  if (requiresAuth && !isAuthenticated) {
    ElMessage.error({ message: 'Please login first.', showClose: true })
    next('/')
  } else {
    next()
  }
})

export default router
